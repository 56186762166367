import '../styles/BannerSection.scss'
import PropTypes from 'prop-types';

function BannerSection({ headline, subline, text, buttonText,href }) {
    return (
        <section className="banner-section d-flex align-items-center justify-content-center text-center text-white p-5 spacing-section">
            <div className="container">
                <h2 >{headline}</h2>
                <p className="lead">{subline}</p>
                <p>{text}</p>
                <a className="btn btn-primary mt-3" href={href}>{buttonText}</a>
            </div>
        </section>
    );
}

BannerSection.propTypes = {
    headline: PropTypes.string.isRequired,
    subline: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
};

export default BannerSection;
