const Patchlogs = () => {
    const logs = [
        { date: '05.08.2024',  hours: '2', log: 'Livegang' },
        { date: '06.08.2024',  hours: '3', log: 'Setup Impressum sowie Homepage-Styling' },
        { date: '07.08.2024',  hours: '1', log: 'Google Analytics eingerichtet' },
        { date: '20.08.2024',  hours: '2', log: '.htaccess & .htpasswd eingerichtet' },
        { date: '23.08.2024',  hours: '2', log: 'Mobile Navigation aufgebaut' },
    ];


    return (
        <div className="container spacing-section" id="impress">
            <h1 className="mb-4">Patchlogs</h1>
            <table className="table table-striped">
                <thead>
                <tr>
                    <th>Datum</th>
                    <th>Arbeitsstunden</th>
                    <th>Beschreibung</th>
                </tr>
                </thead>
                <tbody>
                {logs.map((log, index) => (
                    <tr key={index}>
                        <td>{log.date}</td>
                        <td>{log.hours}</td>
                        <td>{log.log}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default Patchlogs;
