import '../styles/navigation.scss'
import {BrowserRouter, Route, Routes, useLocation} from "react-router-dom";
import Layout from "../pages/Layout";
import Home from "../pages/Home";
import Impress from "../pages/Impress";
import Contact from "../pages/Contact";
import NoPage from "../pages/NoPage";
import Patchlogs from "../pages/Patchlogs";
import {useEffect} from "react";


function Navigation() {
    const ScrollToTop = () => {
        const { pathname } = useLocation();

        useEffect(() => {
            window.scrollTo(0, 0);
        }, [pathname]);

        return null;
    };

    return (
        <>
            <BrowserRouter>
                <ScrollToTop />
                <Routes>
                    <Route path="/" element={<Layout/>}>
                        <Route index element={<Home/>}/>
                        <Route path="impressum" element={<Impress/>}/>
                        <Route path="kontakt" element={<Contact/>}/>
                        <Route path="patchlogs" element={<Patchlogs/>}/>
                        <Route path="*" element={<NoPage/>}/>
                    </Route>
                </Routes>
            </BrowserRouter>
        </>
    );
}

Navigation.propTypes = {};

export default Navigation;