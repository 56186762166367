import './App.scss';
import './styles/fonts.scss';

import './styles/bootstrap.scss';
import Navigation from "./components/component";
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

export default function App() {
  return (
    <div className="App">
      <Navigation />
    </div>
  );
}
