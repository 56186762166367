import '../styles/WasteList.scss'
import {useEffect, useRef} from "react";

function WasteList() {
    const imageRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            const elements = document.querySelectorAll(".waste-item img");
            elements.forEach((element) => {
                const { top } = element.getBoundingClientRect();
                element.style.transform = `translateY(${top * 0.2}px)`;
            });
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, );

    const items = [
        {name: "Glas", image: "assets/img/glass.jpg", description: "Recycling&shy;fähiges Glas"},
        {name: "Papier", image: "assets/img/paper.jpg", description: "Papier und Karton"},
        {name: "Folie", image: "assets/img/foil.jpg", description: "Verpackungs&shy;folie"},
        {name: "Kunst&shy;stoff", image: "assets/img/plastic.jpg", description: "Plastik&shy;abfälle"},
        {name: "Tapete&shy;nreste", image: "assets/img/wallpaper.jpg", description: "Reste von Tapeten"},
        {name: "Verbunds&shy;toffe", image: "assets/img/composites.jpg", description: "Material&shy;verbund&shy;stoffe"},
        {name: "Holz&shy;reste", image: "assets/img/wood.jpg", description: "Holz&shy;abfälle"},
        {name: "Texti&shy;lien", image: "assets/img/fabric.jpg", description: "Textil&shy;abfälle"},
        {name: "Metall", image: "assets/img/metall.jpg", description: "Metall&shy;abfälle"},
        {name: "Elek&shy;tronik", image: "assets/img/electronic.jpg", description: "Elektro&shy;geräte"},
        {name: "Möbel", image: "assets/img/furniture.jpg", description: "Alte Möbel&shy;stücke"},
        {name: "Keramik", image: "assets/img/ceramic.jpg", description: "Zerbrochene Keramik"},
        {name: "Gummi", image: "assets/img/rubber.jpg", description: "Gummi&shy;abfälle"},
        {name: "Bio&shy;abfall", image: "assets/img/food.jpg", description: "Kompostier&shy;bare Abfälle"}
    ];
    return (
        <a className="wastelist">
            <div className=" container spacing-section">
                <h2 className="text-center mb-2">Misch&shy;abfall | Ge&shy;werbe&shy;abfall</h2>
                <h3 className="text-center mb-5">Das darf rein:</h3>
                <div className="row">
                    {items.map((item, index) => (
                        <div className="col-6 col-lg-4" key={index}>
                            <div className="waste-item">
                                <img  ref={imageRef} src={item.image} alt={item.name}/>
                                <div className="text-container">
                                    <h3 dangerouslySetInnerHTML={{ __html: item.name }}></h3>
                                    <small dangerouslySetInnerHTML={{ __html: item.description }}></small>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </a>
    );
}

export default WasteList;